<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Working from home<br>
                                                                                Desktop</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper sml_couches">
                                                                            <p>
                                                                                <router-link :to="{ name: 'couches-a45' }"><em>Up
                                                                                        Next: </em> Couches <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1637369262555"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <div class="heading_wrapper">
                                                            <h2>Do you even have a desk?</h2>
                                                            <p><a class="headerVideoLink popup-modal"
                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal1"><img
                                                                        src="../../assets/images/airbnb/Airbnb-Video-Play.png">Watch
                                                                    video now</a></p>
                                                        </div>
                                                        <p>No matter where you find yourself working these days, it is
                                                            essential to find a flat work surface (i.e., desk, tabletop,
                                                            lap desk) to set your keyboard, mouse and monitor on.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                                            <div class="vc_column-inner vc_custom_1637191002667">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  no-margin">
                                                                        <div class="wpb_wrapper">
                                                                            <div class="heading-wrapper desk_next">
                                                                                <p><img
                                                                                        src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-and-Mouse-Set-up-e1636574640532.png">
                                                                                </p>
                                                                                <h3>Your keyboard and mouse</h3>
                                                                            </div>
                                                                            <p>&nbsp;</p>
                                                                            <p>Keep your keyboard and mouse as close to
                                                                                you as possible, and make sure they are
                                                                                placed at, or slightly below, your
                                                                                elbows. If needed, you can adjust your
                                                                                desk height or chair height. If your
                                                                                chair doesn’t adjust, you can sit on a
                                                                                pillow or a folded towel if you need
                                                                                extra height (while making sure your
                                                                                feet remain supported).</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                                            <div class="vc_column-inner vc_custom_1637191007234">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  no-margin">
                                                                        <div class="wpb_wrapper">
                                                                            <div class="heading-wrapper desk_next">
                                                                                <p><img
                                                                                        src="../../assets/images/airbnb/Airbnb-Equipment-Screens-Set-Up-e1636574652535.png">
                                                                                </p>
                                                                                <h3>Your monitors and screens</h3>
                                                                            </div>
                                                                            <p>&nbsp;</p>
                                                                            <p>To avoid slouching, make sure your
                                                                                screens, including computer monitors,
                                                                                laptops, tablets and phones are propped
                                                                                up and positioned directly in front of
                                                                                you, about an arm’s distance away and
                                                                                slightly below eye level. This is
                                                                                especially important when using a
                                                                                laptop. To avoid a low laptop screen,
                                                                                use an external monitor or a laptop
                                                                                riser with an external keyboard and
                                                                                mouse.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from home journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                                <router-link :to="{ name: 'home-essentials-a45' }">
                                                                                    <div
                                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width="750" height="500"
                                                                                            src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Essentials-.jpg"
                                                                                            class="vc_single_image-img attachment-full"
                                                                                            alt="" loading="lazy"
                                                                                            title="Airbnb-Work-From-Home---Home-Essentials-">
                                                                                    </div>
                                                                                </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'home-essentials-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>The essentials</h4>
                                                                                <p>Create a separate and distinct work space
                                                                                    in your home.</p>
                                                                            </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'seating-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="530"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Seating.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Seating"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'seating-a45' }">
                                                                           <div class="wpb_wrapper">
                                                                               <h4>Seating</h4>
                                                                               <p>It’s safe to say that you sit a lot
                                                                                   during your workday.</p>
                                                                           </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'desktop-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Home-Desktop.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Home---Desktop"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'desktop-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Desktop</h4>
                                                                                <p>Learn where to place your work items for
                                                                                    optimal comfort and productivity.</p>
                                                                            </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'couches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Couches-Landing-page-Footer-and-Page.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Couches---Landing-page,-Footer-and-Page"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'couches-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Couches</h4>
                                                                                <p>While it’s best to work at a desk or
                                                                                    table top.</p>
                                                                            </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'household-ergo-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="892"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-Work-From-Home-Household-Ergo2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT = Work From Home Household Ergo2"
                                                                                       >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'household-ergo-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Household ergo</h4>
                                                                                <p>Ergonomics at home doesn’t have to be
                                                                                    fancy.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Stretches"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'home-stretches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Home stretches</h4>
                                                                            <p>These stretches to help you feel good
                                                                                while you work from home.</p>

                                                                        </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                           <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed1"
												src="https://player.vimeo.com/video/395333106?h=78605ec57d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>


                    </div>
                </div>
              

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'desktop-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
        methods: {
			hideModal() {
			document.getElementById("closed1").src += "";
			},
		}
    }
</script>